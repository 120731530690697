import './style.scss'

import Icon from '@/app/base/components/.v2/Icon'
import { SHIPMENT_STATE } from '@/constants'
import { ShipmentsContext } from '@/contexts'
import { useTranslation } from '@/hooks'
import { IShipmentHistoryDetails } from '@/models'
import { eventHandler } from '@/utils'
import { seeInMap, showInMapWithOriginDestination } from '@/utils/seeInMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IProofOfDeliveryResult } from 'Trunkrs-SDK/dist/models/delivery/Shipment'

import DriverRemark from '../driverRemark'
import { ShipmentActions } from '../index'

export interface IDeliveryAttemptsProps {
  proofOfDelivery: IProofOfDeliveryResult
  attempts: IShipmentHistoryDetails[]
}

const DeliveryAttempts: React.SFC<IDeliveryAttemptsProps> = ({
  attempts,
  proofOfDelivery,
}) => {
  const [t] = useTranslation(['SHIPMENT_DETAILS'])

  const attemptCards = useMemo(
    () =>
      [
        ...attempts.map((attempt, index) => ({
          ...attempt,
          attemptNum: index + 1,
        })),
      ]
        .reverse()
        .map((attempt, index) => {
          const isRecentAttempt = index == 0
          return (
            <AttemptCard
              isRecentAttempt={isRecentAttempt}
              attempt={attempt}
              key={attempt.attemptNum}
              attemptNum={attempt.attemptNum}
              proofOfDelivery={proofOfDelivery}
            />
          )
        }),
    [attempts]
  )

  return <>{attemptCards}</>
}

export interface IAttemptCardProps {
  attempt: IShipmentHistoryDetails
  attemptNum: number
  isRecentAttempt: boolean
  proofOfDelivery: IProofOfDeliveryResult
}
export interface IGeoStampLocation {
  longitude: number
  latitude: number
}
export interface ISigningLocation {
  longitude: number
  latitude: number
}

interface INeighbourLocation {
  name: string
  address: string
  postalCode: string
  city: string
  country: string
}

const AttemptCard: React.FC<IAttemptCardProps> = ({
  isRecentAttempt,
  attempt: data,
  attemptNum,
  proofOfDelivery,
}) => {
  const [showDriverRemark, setShowDriverRemark] = useState(false)
  const [shipmentLogMeta, setShipmentLogMeta] = useState<{}>({})
  const [hasGeoStampLocation, setGeoStampLocation] = useState(false)

  const {
    activeShipment: shipment,
    activeShipmentDetails: shipmentDetails,
  } = useContext(ShipmentsContext)

  const remark = get(shipment, 'getRemarks', null)
  const senderRemark = get(shipment, 'getMerchantDeliveryRemark', null)
  const lbdRemark = get(shipmentDetails, 'leaveBehindPermission.remark', null)
  const lbdPhoto = get(shipmentDetails, 'leaveBehindPermission.picture', null)
  const neighbourCardNo = get(shipment, 'neighborCardNumber')
  const getLogsMeta = get(shipment, 'getLogsMeta')

  useEffect(() => {
    ;(async () => {
      const logsMeta: any = await Promise.all(await getLogsMeta)
      const shipmentLogMeta = logsMeta.find(
        (log: {
          version: number
          geoStampLat: number
          geoStampLong: number
        }) => {
          if (log.version === 0) {
            const logs = {
              geoLocation: {
                latitude: log.geoStampLat,
                longitude: log.geoStampLong,
              },
            }
            setShipmentLogMeta(logs)
            const geoLoc: IGeoStampLocation = get(logs, 'geoLocation')
            const { longitude: geoLongitude, latitude: geoLatitude } = geoLoc
            setGeoStampLocation(!!geoLongitude && !!geoLatitude)
          }
        }
      )
    })()
  }, [data])

  const hasPOD = useMemo(
    () => proofOfDelivery.enabled && proofOfDelivery.proof,
    [proofOfDelivery]
  )

  const isPODEnabled = proofOfDelivery.enabled
  const signingLocation: ISigningLocation = get(data, 'signingLocation')
  const { longitude, latitude } = signingLocation
  const hasSigningLocation = !!longitude && !!latitude

  const [t] = useTranslation([
    'SHIPMENT',
    'SHIPMENT_DETAILS',
    'SHIPMENT_ACTIONS',
    'REASON_CODES',
    'DRIVER_REMARK',
  ])

  const getClassAndText = (status: string) => {
    const {
      SHIPMENT_DELIVERED,
      SHIPMENT_NOT_DELIVERED,
      SHIPMENT_DELIVERED_TO_NEIGHBOR,
      SHIPMENT_ACCEPTED_BY_DRIVER,
      CHANGE_SHIPMENT_POST_PONED,
      EXCEPTION_SHIPMENT_NOT_ARRIVED,
      EXCEPTION_SHIPMENT_MIS_SORTED,
      EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER,
      EXCEPTION_SHIPMENT_MISSING,
      EXCEPTION_SHIPMENT_LOST,
      EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER,
      EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
      RETURN_SHIPMENT_TO_SENDER,
      ADDRESS_CHANGE_REQUIRED,
    } = SHIPMENT_STATE

    switch (status) {
      case SHIPMENT_DELIVERED:
        return {
          className: 'delivered-state',
          text: 'SUCCESSFULLY_DELIVERED',
        }

      case SHIPMENT_DELIVERED_TO_NEIGHBOR:
        return {
          className: 'delivered-state',
          text: 'SHIPMENT_DELIVERED_TO_NEIGHBOR',
        }

      case SHIPMENT_NOT_DELIVERED:
      case CHANGE_SHIPMENT_POST_PONED:
      case EXCEPTION_SHIPMENT_NOT_ARRIVED:
      case EXCEPTION_SHIPMENT_MIS_SORTED:
      case EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER:
      case EXCEPTION_SHIPMENT_MISSING:
      case EXCEPTION_SHIPMENT_LOST:
      case EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER:
      case EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS:
      case RETURN_SHIPMENT_TO_SENDER:
      case ADDRESS_CHANGE_REQUIRED:
      case 'EXCEPTION_SHIPMENT_INVESTIGATE':
        return {
          className: 'not-delivered-state',
          text: 'SHIPMENT_NOT_DELIVERED',
        }

      case SHIPMENT_ACCEPTED_BY_DRIVER:
        return {
          className: 'in-transit-state',
          text: 'SHIPMENT_IN_TRANSIT',
        }

      default:
        return {
          className: 'planned-state',
          text: 'SHIPMENT_PLANNED',
        }
    }
  }

  const statusObject = useMemo(() => getClassAndText(data.status), [data])

  const showDriverRemarkModal = useCallback(
    (open: boolean) => eventHandler(setShowDriverRemark, open),
    [setShowDriverRemark, eventHandler]
  )

  const neighbourLocation: INeighbourLocation = get(data, 'neighbourLocation')

  return (
    <div id="attempt-card" className="box-shadow">
      <div className="wrapper">
        <div className="header">
          <div className="date">{data.date}</div>
          <div className="label attempt_num">
            {t(`SHIPMENT:ATTEMPT_NUM`, { number: attemptNum })}
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="tour-region-position">
          <div>
            <div className="label">{t(`SHIPMENT_DETAILS:TOUR`)}</div>
            <div className="data">{data.tourId || '-'}</div>
          </div>
          <div>
            <div className="label">{t(`SHIPMENT_DETAILS:REGION`)}</div>
            <div className="data">{data.region || '-'}</div>
          </div>
          <div>
            <div className="label">{t(`SHIPMENT_DETAILS:POSITION`)}</div>
            <div className="data">{data.position || '-'}</div>
          </div>
          <div>
            <div className="label">
              {t(`SHIPMENT_DETAILS:DISTANCE_TO_DEPOT`)}
            </div>
            <div className="data">
              {get(data, 'distance') ? (
                <a
                  href={showInMapWithOriginDestination(
                    {
                      lat: get(data, 'distance.subcoLatitude'),
                      long: get(data, 'distance.subcoLongitude'),
                    },
                    {
                      lat: get(data, 'distance.scanLatitude'),
                      long: get(data, 'distance.scanLongitude'),
                    }
                  )}
                  target="_blank"
                >
                  {`${(get(data, 'distance.distanceFromHub', 0) / 1000).toFixed(
                    1
                  )} KM`}{' '}
                  <Icon name="redirect" />
                </a>
              ) : (
                <span className="font-sm">
                  {t(`SHIPMENT_DETAILS:NOT_YET_RETURNED`)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="driver-profile">
          {data.driverPicture ? (
            <img src={data.driverPicture} />
          ) : (
            <FontAwesomeIcon className="default-avatar" icon="user-circle" />
          )}
          <div>
            <span>
              {data.driverName || t(`SHIPMENT_DETAILS:NOT_YET_ASSIGNED`)}
            </span>
            <span>{data.driverContact}</span>
          </div>
        </div>

        {lbdRemark && (
          <div className="section">
            <div className="label">
              {t(`SHIPMENT_DETAILS:LEAVE_BEHIND_REMARK`)}
            </div>
            <div className="data">{lbdRemark}</div>
          </div>
        )}

        <div className="section">
          <div className="label">{t(`SHIPMENT_DETAILS:DRIVER_REMARK`)}</div>
          {remark ? (
            <div className="data">
              {remark}
              <button
                className="btn button action icon bg-transparent edit"
                onClick={showDriverRemarkModal(true)}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </button>
            </div>
          ) : (
            <a
              className="set-driver-remark-link"
              onClick={showDriverRemarkModal(true)}
            >
              {t(`DRIVER_REMARK:SET_DRIVER_REMARK`)}
            </a>
          )}
        </div>

        {senderRemark && (
          <div className="section">
            <div className="label">{t(`SHIPMENT_DETAILS:SENDER_REMARK`)}</div>
            <div className="data">{senderRemark}</div>
          </div>
        )}

        {isPODEnabled && hasPOD && (
          <div className="section">
            <div className="label">{t(`PROOF_OF_DELIVERY`)}</div>
            <div className="data font-bold">
              {t(`DOCUMENT_TYPE`)}:
              <span>{proofOfDelivery.proof && proofOfDelivery.proof.type}</span>
            </div>
            <div className="data font-bold">
              {t(`LAST_DIGITS`)}:
              <span>
                {proofOfDelivery.proof && proofOfDelivery.proof.digits}
              </span>
            </div>
          </div>
        )}

        {isPODEnabled && statusObject.text === 'SUCCESSFULLY_DELIVERED' && (
          <div className="section">
            {hasSigningLocation ? (
              <div className="locate-gps">
                <a
                  href={seeInMap(get(data, 'signingLocation'))}
                  target="_blank"
                  className="btn button box-shadow"
                  type="button"
                >
                  {t('SHIPMENT:LOCATE_GPS')} <Icon name="outgoing" />
                </a>
              </div>
            ) : (
              <div className="signature-area--unknown data">
                <a>{t(`SHIPMENT:DELIVERY_LOCATION_UNKNOWN`)}</a>
              </div>
            )}
          </div>
        )}

        {statusObject.text === 'SHIPMENT_NOT_DELIVERED' && (
          <div className="section">
            {hasGeoStampLocation ? (
              <div className="locate-gps">
                <a
                  href={seeInMap(get(shipmentLogMeta, 'geoLocation'))}
                  target="_blank"
                  className="btn button box-shadow"
                  type="button"
                >
                  {t('SHIPMENT:LOCATE_GPS')} <Icon name="outgoing" />
                </a>
              </div>
            ) : hasSigningLocation ? (
              <div className="locate-gps">
                <a
                  href={seeInMap(get(data, 'signingLocation'))}
                  target="_blank"
                  className="btn button box-shadow"
                  type="button"
                >
                  {t('SHIPMENT:LOCATE_GPS')} <Icon name="outgoing" />
                </a>
              </div>
            ) : (
              <div className="signature-area--unknown data">
                <a>{t(`SHIPMENT:DELIVERY_LOCATION_UNKNOWN`)}</a>
              </div>
            )}
          </div>
        )}

        {data.additionalInformation && (
          <div className="section">
            <div className="label">
              {t(`SHIPMENT_DETAILS:ADDITIONAL_INFORMATION`)}
            </div>
            <div className="data">{data.additionalInformation}</div>
          </div>
        )}

        {lbdPhoto && (
          <div className="section">
            <div className="label">{t(`PROOF_OF_DELIVERY`)}</div>
            <div className="signature-area data">
              <div>
                <img alt="Leave behind picture" src={lbdPhoto} />
              </div>
            </div>
            <div className="locate-gps">
              <a
                href={seeInMap(get(data, 'signingLocation'))}
                target="_blank"
                className="btn button box-shadow"
                type="button"
              >
                {t('SHIPMENT:SEE_SIGNING_LOCATION')} <Icon name="outgoing" />
              </a>
            </div>
          </div>
        )}

        {isRecentAttempt && (
          <div className="section">
            <h2 className="label">{t('SHIPMENT_ACTIONS:ACTIONS')}</h2>
            <ShipmentActions />
          </div>
        )}

        {data.signature && statusObject.text !== 'SHIPMENT_IN_TRANSIT' && (
          <div className="section">
            <div className="label">{t(`SHIPMENT_DETAILS:SIGNATURE`)}</div>
            <div className="signature-area data">
              <div>
                <img src={data.signature} />
              </div>
            </div>
            <div className="locate-gps">
              <a
                href={seeInMap(get(data, 'signingLocation'))}
                target="_blank"
                className="btn button box-shadow"
                type="button"
              >
                {t('SHIPMENT:SEE_SIGNING_LOCATION')} <Icon name="outgoing" />
              </a>
            </div>
          </div>
        )}

        {neighbourLocation.address &&
          statusObject.text === 'SHIPMENT_DELIVERED_TO_NEIGHBOR' && (
            <div className="section">
              <div className="label">
                {t(`SHIPMENT_DETAILS:NEIGHBOUR_ADDRESS`)}
              </div>
              <div className="data font-bold">
                {t(`SHIPMENT_DETAILS:NEIGHBOUR_NAME`)}:
                <span>{neighbourLocation.name}</span>
              </div>
              <div className="data font-bold">
                {t(`SHIPMENT_DETAILS:ADDRESS`)}:
                <span>{neighbourLocation.address}</span>
              </div>
              <div className="data font-bold">
                {t(`SHIPMENT_DETAILS:POSTAL_CODE`)}:
                <span>{neighbourLocation.postalCode}</span>
              </div>
              <div className="data font-bold">
                {t(`SHIPMENT_DETAILS:COUNTRY`)}:
                <span>{neighbourLocation.country}</span>
              </div>
              <div className="data font-bold">
                {t(`SHIPMENT_DETAILS:CITY`)}:
                <span>{neighbourLocation.city}</span>
              </div>
            </div>
          )}

        {neighbourCardNo && isRecentAttempt && (
          <div className="section">
            <div className="label">
              {t(`SHIPMENT_DETAILS:NEIGHBOUR_CARD_NUMBER`)}
            </div>
            <div className="data">{neighbourCardNo}</div>
          </div>
        )}
      </div>

      <div className={`status box-shadow ${statusObject.className}`}>
        {t(`SHIPMENT:${statusObject.text}`)}
      </div>
      {showDriverRemark && (
        <DriverRemark handleClose={showDriverRemarkModal(false)} />
      )}
    </div>
  )
}

export default DeliveryAttempts
