export enum REASON_CODE {
  ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',
  DELIVERY_DAMAGED = 'DELIVERY_DAMAGED',
  NO_TIME_LEFT_IN_TIMESLOT = 'NO_TIME_LEFT_IN_TIMESLOT',
  RECIPIENT_NOT_AT_HOME = 'RECIPIENT_NOT_AT_HOME',
  DRIVER_INVOLVED_IN_ACCIDENT = 'DRIVER_INVOLVED_IN_ACCIDENT',
  REFUSED_BY_CUSTOMER = 'REFUSED_BY_CUSTOMER',
  BUSINESS_CLOSED = 'BUSINESS_CLOSED',
  BAD_ADDRESS = 'BAD_ADDRESS',
  EXTREME_WEATHER_CONDITIONS = 'EXTREME_WEATHER_CONDITIONS',
  PARCEL_MISSING = 'PARCEL_MISSING',
  DELIVER_ADDRESS_NOT_ACCESSIBLE = 'DELIVER_ADDRESS_NOT_ACCESSIBLE',
  SHIPMENT_MISSING = 'SHIPMENT_MISSING',
  HEAVILY_DAMAGED = 'HEAVILY_DAMAGED',
  TOO_HEAVY = 'TOO_HEAVY',
  TOO_LARGE = 'TOO_LARGE',
  SHIPMENT_DELAYED = 'SHIPMENT_DELAYED',
  VEHICLE_FULL = 'VEHICLE_FULL',
  NOT_PLANNED = 'NOT_PLANNED',
  MISSED_CONNECTION = 'MISSED_CONNECTION',
}
