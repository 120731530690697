import API from '@/api'
import { AppContext, DriverContext } from '@/contexts'
import { useResponseErrorHandler } from '@/hooks'
import {
  IAddressChangeParameters,
  IAddressHistory,
  ICallLog,
  IDeliveryDetails,
  IFreezerLog,
  IFrozenFoodDeliveryAttempt,
  IHubLog,
  INeighbourParams,
  IOverviewShipmentsResultFilter,
  IReturnScan,
  IShipment,
  IShipmentDetails,
  IShipmentHistoryDetails,
  SHIPMENT_STATES,
  ShipmentLog,
  ShipmentOverview,
  State,
  TimeSlot,
} from '@/models'
import { IShipmentsState } from '@/store/shipments/state'
import { arrayReplace } from '@/utils'
import extractTagsFromSubcos from '@/utils/extractTagsFromSubcos'
import {
  defaultTo,
  get,
  groupBy,
  isEqual,
  last,
  orderBy,
  pick,
  sortBy,
  uniqWith,
} from 'lodash'
import chunk from 'lodash/chunk'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useContext, useState } from 'react'
import { REASON_CODE } from 'Trunkrs-SDK/dist/models/delivery/ReasonCode'

import { IAuditLogs } from '../../models/index'
import { isNotApplicable } from '../../utils/isNotApplicable'

export const useShipmentActions = (initialState: IShipmentsState) => {
  const shipment = API.Shipment()
  const location = API.Location()
  const merchant = API.Merchant()
  const reasonCode = API.ReasonCode()
  const communication = API.Communication()
  const subcontractor = API.Subco()

  const { allDriversWithSubcoId } = useContext(DriverContext)
  const { setLoading } = useContext(AppContext)

  const [hubLogs, setHubLogs] = useState<{ [id: string]: IHubLog[] }>({})
  const [auditLogs, setAuditLogs] = useState<{ [id: string]: IAuditLogs[] }>({})
  const [openToursResponseMessage, setOpenToursResponseMessage] = useState('')
  const [reOpenShipmentLoading, setReOpenShipmentLoading] = useState<boolean>(
    false
  )

  const [overviewShipments, setOverviewShipments] = useState(
    initialState.overviewShipments
  )

  const [filteredShipmentIds, setFilteredShipmentIds] = useState(
    initialState.filteredShipmentIds
  )

  const [addressHistory, setAddressHistory] = useState<Array<IAddressHistory>>(
    []
  )
  const [activeOverviewShipment, setActiveOverviewShipment] = useState(
    initialState.activeOverviewShipment
  )
  const [activeShipment, setActiveShipment] = useState(
    initialState.activeShipment
  )
  const [activeShipmentLogs, setActiveShipmentLogs] = useState<
    Array<ShipmentLog>
  >([])
  const [activeShipmentDetails, setActiveShipmentDetails] = useState(
    initialState.activeShipmentDetails
  )
  const [
    overviewShipmentsResultFilter,
    setOverviewShipmentsResultFilter,
  ] = useState<IOverviewShipmentsResultFilter>(
    initialState.overviewShipmentsResultFilter
  )
  const [frozenFoodDeliveryAttempts, setFrozenFoodDeliveryAttempts] = useState(
    initialState.frozenFoodDeliveryAttempts
  )
  const [
    accumulatedOutOfFreezerTime,
    setAccumulatedOutOfFreezerTime,
  ] = useState(initialState.accumulatedOutOfFreezerTime)
  const [maxTimeOutOfFreezer, setMaxTimeOutOfFreezer] = useState(
    initialState.maxTimeOutOfFreezer
  )

  const [callLogs, setCallLogs] = useState<{
    [key: string]: Array<ICallLog>
  } | null>(null)

  const [searchMode, setSearchMode] = useState(initialState.searchMode)
  const [fetching, setFetching] = useState(initialState.fetching)

  const [availableRegions, setAvailableRegions] = useState(
    initialState.availableRegions
  )

  const [merchantConfig, setMerchantConfig] = useState()

  const fetchHubLogs = async (shipmentId: number) => {
    const [, hubLogs] = await useResponseErrorHandler(
      shipment.fetchHubLogsByShipmentId(shipmentId)
    )
    const [, shipmentReturnScans] = await useResponseErrorHandler(
      shipment.fetchReturnScans(shipmentId)
    )
    const hubLogsWithDistance = hubLogs.map((hubLog: IHubLog) => {
      const returnScanInfo = shipmentReturnScans.find(
        (shipmentReturn: IReturnScan) =>
          get(shipmentReturn, 'hublogDate') === hubLog.createdAt
      )

      if (returnScanInfo) {
        const {
          distanceFromHub,
          scanLatitude,
          scanLongitude,
          subcoLatitude,
          subcoLongitude,
        } = returnScanInfo
        return {
          ...hubLog,
          distance: {
            distanceFromHub,
            scanLatitude,
            scanLongitude,
            subcoLatitude,
            subcoLongitude,
          },
        }
      }
      return hubLog
    })

    if (!isEmpty(allDriversWithSubcoId)) {
      const logsWithOwner = await hubLogsWithDistance.reduce(
        async (track: any, log: IHubLog) => {
          const acc = await track
          const owner = allDriversWithSubcoId.find(
            d => d.userSub === log.userSub
          )
          if (!!owner) {
            log.owner = {
              ...owner,
              id: owner.userSub,
            }
            acc.push(log)
            return acc
          }
          // fetch icognito profile
          const [, profile] = await useResponseErrorHandler(
            communication.fetchAdminProfile(log.userSub)
          )
          const name = isNotApplicable(get(profile, 'name', ''))
            ? get(profile, 'email')
            : 'n/a'
          log.owner = {
            name,
            id: log.userSub,
          }
          acc.push(log)
          return acc
        },
        Promise.resolve([])
      )

      const sortedLogs = groupBy(
        sortBy(logsWithOwner, 'createdAt').reverse(),
        (item: any) => moment(new Date(item.createdAt)).format('DD-MM-YYYY')
      )

      setHubLogs(sortedLogs)
    }
  }

  const mapFrozenFoodDeliveryAttempts = (
    freezerLogs: IFreezerLog[],
    deliveryAttempts: IShipmentHistoryDetails[]
  ): IFrozenFoodDeliveryAttempt[] =>
    deliveryAttempts.map(
      (deliveryAttempt: IShipmentHistoryDetails, index: number) => ({
        date: deliveryAttempt.date,
        logs: freezerLogs.filter(
          (freezerLog: IFreezerLog) =>
            deliveryAttempt.date ===
            moment(freezerLog.createdAt)
              .startOf('day')
              .format('DD-MM-YYYY')
        ),
        attemptNum: index,
      })
    )

  const getTourOverview = async (shipment = activeShipment) => {
    if (!shipment) {
      return { collations: {}, driver: {} }
    }
    const [, overview] = await useResponseErrorHandler(
      shipment.getTourOverview()
    )
    return defaultTo(overview, { collations: {}, driver: {} })
  }

  const fetchShipmentLogs = async (shipment = activeShipment) => {
    if (shipment) {
      const [, pendingLogs] = await useResponseErrorHandler(
        shipment.getLogsMeta
      )
      const [, response] = await useResponseErrorHandler(
        Promise.all(defaultTo(pendingLogs, []))
      )
      return defaultTo(response, [])
    }
    return []
  }

  const fetchShipmentTourEstimation = async (shipment = activeShipment) => {
    if (shipment) {
      const [, collation] = await useResponseErrorHandler(shipment.getCollation)
      if (collation) {
        const [, response] = await useResponseErrorHandler(
          Promise.all([collation.getTour, collation.getEstimation])
        )
        if (response) {
          return {
            tour: response[0],
            estimation: response[1],
          }
        }
      }
    }
    return null
  }

  const fetchShipmentRecipient = async (shipment = activeShipment) => {
    if (shipment) {
      const [, response] = await useResponseErrorHandler(shipment.getRecipient)

      return response
    }
    return null
  }

  const fetchOverviewShipments = async (
    page: number = overviewShipmentsResultFilter.currentPage,
    perPage: number = overviewShipmentsResultFilter.resultsPerPage
  ) => {
    setFetching(true)
    const [err, response] = await useResponseErrorHandler(
      ShipmentOverview.findOverviewShipments(page, perPage)
    )
    setFetching(false)
    if (!err) {
      const { pageData: shipments } = response
      setOverviewShipments(
        defaultTo(
          orderBy(
            shipments,
            (shipment: IShipment) => shipment.deliveryDate,
            'desc'
          ),
          []
        )
      )
    } else {
      setOverviewShipments([])
    }

    setOverviewShipmentsResultFilter({
      ...overviewShipmentsResultFilter,
      query: undefined,
      date: undefined,
      driver_id: undefined,
      subco_tag: undefined,
      merchant_name: undefined,
      state_name: undefined,
      currentPage: page,
      resultsPerPage: perPage,
      endOfResults: get(response, 'pageData.length', 0) % perPage !== 0,
    })
  }

  const quickFetchShipment = async (
    id: number | null = (activeShipment && activeShipment.getId) || null
  ) => {
    const [err, response] = await useResponseErrorHandler(
      shipment.fetchWithHistoryDetails(id as number)
    )

    if (!err) {
      setActiveShipment(response)
      return response
    }
    return null
  }

  const fetchShipment = async (
    id: number | null = (activeShipment && activeShipment.getId) || null
  ) => {
    const [err, response] = await useResponseErrorHandler(
      shipment.fetchWithHistoryDetails(id as number)
    )

    if (!err) {
      setActiveShipment(response)

      const [, shipmentDetails] = await useResponseErrorHandler(
        response.getHistoryDetails
      )

      const shipmentLogs = await fetchShipmentLogs(response)
      const logZero = getVersionZero(shipmentLogs)
      const statusText = defaultTo(await getShipmentStatusText(logZero), '')
      const deliveryDate = await getShipmentDeliveryDate(logZero)

      if (activeOverviewShipment) {
        const nextActiveOverviewShipment = {
          ...activeOverviewShipment,
          statusText,
          deliveryDate,
        }
        setActiveOverviewShipment(nextActiveOverviewShipment as IShipment)

        if (overviewShipments.length) {
          updateOverviewShipments(nextActiveOverviewShipment as IShipment)
        }
      }

      const [, shipmentReturnScans] = await useResponseErrorHandler(
        shipment.fetchReturnScans(id as number)
      )

      let shipmentReturnScansOrderedByDate = sortBy(
        shipmentReturnScans,
        'hublogDate'
      )

      const shipmentHistory = getDistinctShipmentHistory(
        get(shipmentDetails, 'shipmentHistory')
      ).map(shipmentHistory => {
        const returnScanInfo = shipmentReturnScansOrderedByDate.find(
          (shipmentReturn: any) => {
            const shipmentHistoryDate = moment(
              shipmentHistory.date,
              'DD-MM-YYYY'
            )
            const shipmentReturnScanDate = moment(
              get(shipmentReturn, 'hublogDate')
            )
            return shipmentReturnScanDate.isSameOrAfter(shipmentHistoryDate)
          }
        )

        if (returnScanInfo) {
          const {
            hublogId,
            distanceFromHub,
            scanLatitude,
            scanLongitude,
            subcoLatitude,
            subcoLongitude,
          } = returnScanInfo

          shipmentReturnScansOrderedByDate = shipmentReturnScansOrderedByDate.filter(
            o => get(o, 'hublogId') !== hublogId
          )

          return {
            ...shipmentHistory,
            distance: {
              distanceFromHub,
              scanLatitude,
              scanLongitude,
              subcoLatitude,
              subcoLongitude,
            },
          }
        }

        return shipmentHistory
      })

      const [, proofOfDelivery] = await useResponseErrorHandler(
        shipment.getProofOfDeliveryByShipmentId(Number(id))
      )

      setActiveShipmentDetails({
        ...shipmentDetails,
        shipmentHistory,
        proofOfDelivery,
      })

      setActiveShipmentLogs(shipmentLogs)

      const frozenFoodDeliveryAttempts = mapFrozenFoodDeliveryAttempts(
        get(shipmentDetails.freezerLogs, 'result', []),
        shipmentHistory
      )
      setFrozenFoodDeliveryAttempts(sortLogs(frozenFoodDeliveryAttempts))
      setAccumulatedOutOfFreezerTime(
        get(shipmentDetails.freezerLogs, 'accumulatedOutOfFreezerTime', 0)
      )
      setMaxTimeOutOfFreezer(
        get(shipmentDetails.freezerLogs, 'maxTimeOutsideOfFreezer', 0)
      )

      const callLogsGroupByDate = groupBy(
        get(shipmentDetails, 'callLogs', []).filter(
          ({ recordingId }: ICallLog) => recordingId
        ),
        ({ createdAt }: ICallLog) => moment(createdAt).format('MM-DD-YYYY')
      ) as any
      setCallLogs(callLogsGroupByDate)

      return response
    }

    return null
  }

  const sortLogs = (
    attempts: IFrozenFoodDeliveryAttempt[]
  ): IFrozenFoodDeliveryAttempt[] =>
    attempts.map((attempt: IFrozenFoodDeliveryAttempt) => ({
      ...attempt,
      logs: sortBy(attempt.logs, (log: IFreezerLog) => log.createdAt).reverse(),
    }))

  const getActiveOverviewShipment = async (
    id: number | null = get(activeShipment, 'getId', null)
  ) => {
    if (overviewShipments.length > 0) {
      const nextActiveOverviewShipment = overviewShipments.find(
        shipment => shipment.shipmentId === id
      )
      if (nextActiveOverviewShipment) {
        setActiveOverviewShipment(nextActiveOverviewShipment)

        return nextActiveOverviewShipment
      }
    }
    const [, response] = await useResponseErrorHandler(
      ShipmentOverview.getOverviewShipment(id as number)
    )
    if (response) {
      const { pageData } = response
      const nextActiveOverviewShipment = defaultTo(
        last(pageData) as IShipment,
        null
      )
      setActiveOverviewShipment(nextActiveOverviewShipment)

      return nextActiveOverviewShipment
    }
    return null
  }

  const updateOverviewShipments = (shipment: IShipment) => {
    const nextShipments = arrayReplace(
      overviewShipments,
      { shipmentId: shipment.shipmentId },
      shipment
    )
    setOverviewShipments(nextShipments)
  }

  const fetchShipmentByBarcode = async (barcode: string) => {
    const [err, response] = await useResponseErrorHandler(
      shipment.fetchByBarcode(barcode)
    )
    if (!err) {
      setActiveShipment(response)
      return response
    }
    return null
  }

  const cancelShipment = async (reason: string) => {
    if (activeShipment) {
      const [err] = await useResponseErrorHandler(
        activeShipment.cancel('TRUNKRS', reason)
      )
      if (!err) {
        await fetchShipment()
        return true
      }
    }
    return false
  }

  const postponeShipment = async (date: string) => {
    if (activeShipment) {
      const [err] = await useResponseErrorHandler(activeShipment.postPone(date))
      if (!err) {
        await fetchShipment()
        return true
      }
    }
    return false
  }

  const queuePostponeShipment = async (barcode: string, date: string) => {
    const [err] = await useResponseErrorHandler(
      shipment.batchPostpone(barcode, date, '')
    )
    if (!err) {
      return true
    }
  }

  const setShipmentAsDelivered = async (
    comment: string = '',
    overrideDate: string,
    noReload: boolean = false
  ) => {
    if (activeShipment) {
      const [err] = await useResponseErrorHandler(
        activeShipment.overruleDelivered(comment, overrideDate)
      )
      if (!err && !noReload) {
        await fetchShipment()
        return true
      }
    }
    return false
  }

  const fetchAuditLogs = async (shipmentId: number) => {
    const [, auditLogsResponse] = await useResponseErrorHandler(
      shipment.getShipmentAuditLogsByShipmentId(Number(shipmentId))
    )

    if (!isEmpty(allDriversWithSubcoId) && !isEmpty(auditLogsResponse)) {
      const auditLogs = await auditLogsResponse.reduce(
        async (track: any, log: IAuditLogs) => {
          const acc = await track
          const owner = allDriversWithSubcoId.find(
            d => d.userSub === log.userSub
          )
          // if (!log.source) return acc

          if (!!owner) {
            log.owner = owner.name
            acc.push(log)
            return acc
          }
          // fetch icognito profile
          const [, profile] = await useResponseErrorHandler(
            communication.fetchAdminProfile(log.userSub)
          )
          const name = isNotApplicable(get(profile, 'name', ''))
            ? get(profile, 'email')
            : 'n/a'
          ;(log.owner = name), acc.push(log)
          return acc
        },
        Promise.resolve([])
      )

      const sortedLogs = groupBy(
        sortBy(auditLogs, 'createdAt').reverse(),
        (item: any) => moment(new Date(item.createdAt)).format('DD-MM-YYYY')
      )

      setAuditLogs(sortedLogs)
    }
  }

  const setShipmentAsDeliveredToNeighbour = async (
    neighbour: INeighbourParams,
    comment: string = '',
    overrideDate: string,
    noReload: boolean = false
  ) => {
    if (activeShipment) {
      const [err] = await useResponseErrorHandler(
        activeShipment.overruleDeliveredToNeighbor(
          comment,
          neighbour,
          overrideDate
        )
      )
      if (!err && !noReload) {
        await fetchShipment()
        return true
      }
    }
    return false
  }

  const setShipmentAsNotDelivered = async (
    reasonCode: string,
    comment: string = '',
    overrideDate: string,
    noReload: boolean = false
  ) => {
    if (activeShipment) {
      const [err] = await useResponseErrorHandler(
        activeShipment.overruleNotDelivered(comment, reasonCode, overrideDate)
      )
      if (!err && !noReload) {
        await fetchShipment()
        return true
      }
    }
    return false
  }

  const setShipmentState = async (payload: {
    stateName:
      | 'SHIPMENT_DELIVERED'
      | 'SHIPMENT_NOT_DELIVERED'
      | 'CHANGE_SHIPMENT_POST_PONED'
      | 'EXCEPTION_SHIPMENT_MISSING'
      | 'EXCEPTION_SHIPMENT_LOST'
    comment?: string
    neighbour?: {
      name: string
      address: string
      postalCode: string
      city: string
      country: string
    }
    overrideDate?: string
  }) => {
    if (activeShipment) {
      const [err] = await useResponseErrorHandler(
        activeShipment.overruleShipment(
          get(activeShipment, 'getId') as number,
          payload
        )
      )
      if (!err) {
        await fetchShipment()
        return true
      }
    }
    return false
  }

  const hasSelectedFilter = ({
    query,
    date,
    merchant_name,
    driver_id,
    subco_tag,
    state_name,
  }: any) => {
    const result =
      [date, driver_id, subco_tag, merchant_name, state_name, query].findIndex(
        filter =>
          typeof filter === 'string'
            ? get(filter, 'length', 0) > 0
            : filter != undefined
      ) > -1
    return result
  }

  const searchOverviewShipments = async ({
    query,
    date,
    merchant_name,
    driver_id,
    subco_tag,
    state_name,
    currentPage,
    resultsPerPage,
  }: IOverviewShipmentsResultFilter) => {
    setFetching(true)
    const [err, response] = await useResponseErrorHandler(
      ShipmentOverview.searchOverviewShipments(
        query,
        currentPage,
        resultsPerPage,
        {
          date: date && moment(date).format('YYYY-MM-DD'),
          merchant_name,
          driver_id,
          subco_tag,
          state_name,
        } as any
      )
    )
    if (!err) {
      const { pageData: shipments } = response
      setOverviewShipments(defaultTo(shipments, []))
      const filters = {
        query,
        date,
        merchant_name,
        driver_id,
        subco_tag,
        state_name,
      }
      if (hasSelectedFilter(filters)) {
        const shipmentIds = (await getShipmentIdsByFilter(
          filters as any
        )) as number[]
        setFilteredShipmentIds(shipmentIds)
      } else {
        setFilteredShipmentIds([])
      }
    } else {
      setOverviewShipments([])
      setFilteredShipmentIds([])
    }
    setFetching(false)
    setOverviewShipmentsResultFilter({
      ...overviewShipmentsResultFilter,
      query,
      date,
      merchant_name,
      driver_id,
      subco_tag,
      state_name,
      currentPage,
      resultsPerPage,
      endOfResults:
        get(response, 'pageData.length', 0) %
          overviewShipmentsResultFilter.resultsPerPage !==
        0,
    })
  }

  const searchShipments = async ({
    query,
    date,
    merchant_name,
    driver_id,
    subco_tag,
    state_name,
    currentPage,
    resultsPerPage,
    fetchAllShipmentIds,
  }: IOverviewShipmentsResultFilter) => {
    const [, response] = await useResponseErrorHandler(
      ShipmentOverview.searchOverviewShipments(
        query,
        currentPage,
        resultsPerPage,
        {
          date: date && moment(date).format('YYYY-MM-DD'),
          merchant_name,
          driver_id,
          subco_tag,
          state_name,
        } as any,
        fetchAllShipmentIds
      )
    )

    const shipments = get(response, 'pageData', [])
    const endOfResults =
      shipments.length % overviewShipmentsResultFilter.resultsPerPage !== 0

    setOverviewShipmentsResultFilter({
      ...overviewShipmentsResultFilter,
      endOfResults,
    })

    const filters = {
      query,
      date,
      merchant_name,
      driver_id,
      subco_tag,
      state_name,
    }

    if (hasSelectedFilter(filters)) {
      const shipmentIds = (await getShipmentIdsByFilter(
        filters as any
      )) as number[]
      setFilteredShipmentIds(shipmentIds)
    } else {
      setFilteredShipmentIds([])
    }

    return shipments
  }

  const getShipmentIdsByFilter = async ({
    query,
    date,
    merchant_name,
    driver_id,
    subco_tag,
    state_name,
  }: IOverviewShipmentsResultFilter) => {
    const [, response] = await useResponseErrorHandler(
      ShipmentOverview.searchOverviewShipments(
        query,
        undefined,
        undefined,
        {
          date: date && moment(date).format('YYYY-MM-DD'),
          merchant_name,
          driver_id,
          subco_tag,
          state_name,
        } as any,
        true
      )
    )

    return defaultTo(response, []).map((shipment: { shipmentid: number }) =>
      get(shipment, 'shipmentid', shipment)
    )
  }

  const quickSearch = async (query: string) => {
    const [, response] = await useResponseErrorHandler(
      ShipmentOverview.searchOverviewShipments(query, 1, 5, undefined)
    )
    return get(response, 'pageData', [])
  }

  const printLabel = async (barcode: string) => {
    const [, response] = await useResponseErrorHandler(
      shipment.fetchByBarcode(barcode)
    )
    if (response) {
      const [, label] = await useResponseErrorHandler(
        shipment.printLabel(get(response, 'getTrunkrsNumber'))
      )
      return label
    }
  }

  const fetchAddress = async (postalCode: string, houseNo: string) => {
    const [err, result] = await useResponseErrorHandler(
      location.fetchPostCodeStreetComponents(postalCode, parseInt(houseNo) || 0)
    )
    if (!err) {
      return result
    }
    return null
  }

  const fetchAddressHistory = async () => {
    if (activeShipment) {
      const [err, result] = await useResponseErrorHandler(
        activeShipment.addressHistory()
      )
      if (!err) {
        const sortByDate = sortBy(
          result,
          (addressHistory: IAddressHistory) => addressHistory.timeStamp
        )
        setAddressHistory(sortByDate)
      }
    }
  }

  const changeAddress = async (address: IAddressChangeParameters) => {
    if (activeShipment) {
      const [err, result] = await useResponseErrorHandler(
        activeShipment.changeReceiverAddress(address)
      )
      if (!err) {
        await fetchShipment()
        return result
      }
    }
    return null
  }

  const fetchMerchants = async () => {
    const [err, result] = await useResponseErrorHandler(merchant.getMerchants())
    if (!err) {
      return result
    }
    return null
  }

  const getVersionZero = (logs: Array<ShipmentLog>) =>
    logs.find(log => log.getVersion === 0)

  const getShipmentStatusText = async (versionZeroLog?: ShipmentLog) => {
    if (activeShipment) {
      if (versionZeroLog) {
        const [, response] = await useResponseErrorHandler(
          versionZeroLog.getState
        )
        if (response) {
          return (response as State).getName
        }
      }
    }
  }

  const getDistinctShipmentHistory = (
    shipmentHistory: IShipmentHistoryDetails[] = []
  ) =>
    uniqWith(shipmentHistory, (a, b) =>
      isEqual(pick(a, ['date', 'status']), pick(b, ['date', 'status']))
    )

  const getShipmentDeliveryDate = async (versionZeroLog?: ShipmentLog) => {
    if (versionZeroLog) {
      const timeSlot = (await versionZeroLog.getTimeSlot) as TimeSlot
      return timeSlot.getDate
    }
  }

  const getDeliveryDetails = async () => {
    if (activeShipment) {
      const [, response] = await useResponseErrorHandler(
        shipment.getDeliveryDetails(get(activeShipment, 'getId', 0))
      )
      if (response) {
        return response as IDeliveryDetails
      }
    }
    return null
  }

  const getReasonCodesByShipmentState = async (state: SHIPMENT_STATES) => {
    const [, response] = await useResponseErrorHandler(
      reasonCode.fetchByShipmentState(state)
    )
    return defaultTo(response, [])
  }

  const getRecordLink = async (recordId: number) => {
    const [, response] = await useResponseErrorHandler(
      communication.CallCenter.getRecordLink(recordId)
    )

    return response
  }

  const fetchRegions = async () => {
    const [, subcos] = await useResponseErrorHandler(subcontractor.fetchAll())
    const tags: any[] = extractTagsFromSubcos(subcos)
    if (tags) {
      setAvailableRegions(tags)
    }
  }

  const massApplyState = async (payload: {
    shipmentIds: number[]
    state: SHIPMENT_STATES
    postponedTo?: string
    reasonCode?: REASON_CODE | undefined
  }) => {
    setFilteredShipmentIds([])

    if (payload.shipmentIds.length > 10) {
      const chunkedIds = chunk(payload.shipmentIds, 10)

      const passed: number[] = []
      const failed: number[] = []
      let combined: number[] = []
      let i = 0
      while (i < chunkedIds.length) {
        const [err, response] = await useResponseErrorHandler(
          shipment.massApplyState({
            shipmentIds: get(chunkedIds, i),
            state: get(payload, 'state'),
            reasonCode: get(payload, 'reasonCode'),
            postponedTo: get(payload, 'postponedTo'),
            setAt: get(payload, 'setAt'),
          } as any)
        )
        if (!err) {
          if (response.passed === undefined && response.failed === undefined) {
            combined = combined.concat(response)
          } else {
            passed.concat(response.passed)
            failed.concat(response.failed)
          }
        }
        i++
      }

      if (combined.length > 0) {
        return combined
      }

      return {
        passed,
        failed,
      }
    }

    const [err, response] = await useResponseErrorHandler(
      shipment.massApplyState({
        shipmentIds: payload.shipmentIds,
        state: get(payload, 'state'),
        reasonCode: get(payload, 'reasonCode'),
        postponedTo: get(payload, 'postponedTo'),
        setAt: get(payload, 'setAt'),
      } as any)
    )

    return response
  }

  const reopenCancelledShipment = async () => {
    setReOpenShipmentLoading(true)
    setLoading(true)
    const { shipmentId } = activeShipmentDetails as IShipmentDetails
    const [err, response] = await useResponseErrorHandler(
      shipment.overruleShipmentState(shipmentId)
    )
    if (!err) {
      setOpenToursResponseMessage(response)
    } else {
      setOpenToursResponseMessage('fail')
    }
    setReOpenShipmentLoading(false)
    setLoading(false)
  }

  const fetchMerchantConfig = async (merchantId: number) => {
    const [err, response] = await useResponseErrorHandler(
      merchant.getMerchantConfiguration(merchantId)
    )
    return response
  }

  return {
    overviewShipments,
    overviewShipmentsResultFilter,
    activeShipment,
    activeOverviewShipment,
    activeShipmentLogs,
    setActiveShipment,
    fetchShipment,
    quickFetchShipment,
    getTourOverview,
    getActiveOverviewShipment,
    setActiveOverviewShipment,
    updateOverviewShipments,
    fetchShipmentLogs,
    fetchShipmentRecipient,
    fetchShipmentTourEstimation,
    fetchShipmentByBarcode,
    fetchOverviewShipments,
    fetching,
    searchOverviewShipments,
    setOverviewShipmentsResultFilter,
    searchMode,
    setSearchMode,
    cancelShipment,
    postponeShipment,
    queuePostponeShipment,
    setShipmentAsDelivered,
    setShipmentAsDeliveredToNeighbour,
    setShipmentAsNotDelivered,
    setShipmentState,
    printLabel,
    fetchAddress,
    fetchAddressHistory,
    addressHistory,
    fetchMerchants,
    changeAddress,
    getDeliveryDetails,
    getReasonCodesByShipmentState,
    activeShipmentDetails,
    quickSearch,
    setActiveShipmentDetails,
    frozenFoodDeliveryAttempts,
    accumulatedOutOfFreezerTime,
    maxTimeOutOfFreezer,
    callLogs,
    getRecordLink,
    fetchHubLogs,
    hubLogs,
    fetchAuditLogs,
    auditLogs,
    massApplyState,
    searchShipments,
    fetchRegions,
    availableRegions,
    getShipmentIdsByFilter,
    filteredShipmentIds,
    reopenCancelledShipment,
    reOpenShipmentLoading,
    fetchMerchantConfig,
  } as IShipmentsState
}
