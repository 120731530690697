import { get } from 'lodash'
import React from 'react'

/**
 * Is feature enabled checks whether a feature
 * is disabled via the environment variables
 * @param key: env key
 */
export const isFeatureEnabled = (key: string) =>
  /true/i.test(
    get(
      process.env,
      `REACT_APP_FEATURE_${key.toUpperCase()}`,
      get(featureDefaults, key, true).toString()
    )
  )

const featureDefaults = {
  UPLOAD_CSV: false,
  COLLECTION_TOUR: true,
  COLLECTION_TRACKING: true,
  LINEHAUL_TRACKING: false,
  NOTIFICATIONS: false,
} as { [key: string]: boolean }

/**
 * Feature switch: by default will show the
 * feature, however when the env key is set to
 * false will hide it
 * @param key: env key
 * @param content: whatever content you want
 */
export const featureSwitch = (key: string, content: any) => {
  if (isFeatureEnabled(key)) {
    return content
  }
}

interface IFeatureSwitchProps {
  key: string
  children: React.Component
}

/**
 * FeatureSwitch
 * renders a children when feature is enabled or null
 * when feature is disabled
 * @param key: feature key
 * @param children
 * @constructor
 */
export const FeatureSwitch: React.FC<IFeatureSwitchProps> = ({
  key,
  children,
}) => featureSwitch(key, children) || null
